:root {
    --font-size-1: 16px;
    --color-dark-purple: #2E213B;
    --color-purple: #5C4077;
    --color-light-purple: #f5e9ff;
    --color-yellow: #F2B528;
    --color-green: #00A949;
    --color-expanded-nav: #432F57;
    --color-text: #252525;
}

.App {
    background-color: var(--color-dark-purple);
    background-color: white;
    font-size: var(--font-size-1);
    font-family: 'Rubik-Light';
    color: var(--color-text);

}

.html {
    background-color: var(--color-dark-purple);
}
.HomePage {
    background-color: var(--color-dark-purple);
    
}

.header-main {
    background-color: var(--color-dark-purple);
}
.header-expanded {
    background-color: var(--color-expanded-nav);
    font-size: 0.8em;
}

@keyframes scaleLogo {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
        transform: scale(1);
      }
}
.logo-container {
    width: 100%;
    background-color: var(--color-dark-purple);
    padding-top: 20px;
}

.logo-container:hover {
    cursor: pointer;
}

.logo {
    margin: auto;
    display: block;
    width: 40%;
}
.logo:hover {
    --animation: scaleLogo 1s linear infinite;
}


.link-class {
    text-decoration: none;
}

.nav {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
}
.nav-underline {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
}
.underline {
    width: 40px;
    height: 5px;
    border-bottom: 5px solid white;
    text-align: center;
    margin: 0 auto;
}

.nav-item {
    color: #ffffff;
    text-align: center;
    background-color: var(--color-dark-purple);
    padding-top: 20px;
    padding-bottom: 5px;
    text-decoration: none;
    margin: 0px;
    font-family: 'Rubik-SemiBold';
    opacity: 0.6;

}

.nav-item-pressed {
    color: #ffffff;
    text-align: center;
    background-color: var(--color-dark-purple);
    padding-top: 20px;
    padding-bottom: 5px;
    text-decoration: none;
    font-family: 'Rubik-SemiBold';
    margin: 0px;
}

.nav-item:hover{
    cursor: pointer;
    opacity: 1;
}
.nav-item-pressed:hover{
    cursor: pointer;
}

.nav-instructions {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    background-color: var(--color-expanded-nav);
    padding-top: 10px;
    padding-bottom: 10px;
}

.nav-instructions:hover {
    cursor: pointer;
}

.nav-instructions-item {
    color: #ffffff;
    text-align: center;
    opacity: 0.6;
}

.nav-instructions-item-pressed {
    color: #ffffff;
    text-align: center;
    font-weight: 600;
    opacity: 1;
}

.nav-instructions-item:hover, .nav-instructions-item-pressed:hover{
    cursor: pointer;
    opacity: 1;   
}

.HomePageFooter {
    background-color: var(--color-dark-purple);
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    color: white;
    font-family: 'Rubik-Light';
    font-size: 14px;
}

.footerLine1 {
    margin: 0px;
}

.footerLine2 {
    margin: 0px;
    margin-top: 20px;
}
.footerLine3{
    margin: 0px;
    margin-top: 20px;
    opacity: 0.7;
}

.Content {
    background-color: var(--color-light-purple);
    overflow: hidden;
}
.ContentInstructions {
    background-color: var(--color-light-purple);
    overflow: hidden;
    padding-top: 40px;
    padding-bottom: 40px;
    min-height: 100vh;
}

.scene {

}

.scene-background {
    width: 100%;
    height: auto;
    position: absolute;
    opacity: 0.75;
}

.scene-ui-container {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 100vw;
    min-height: calc(100vw - 20px);
    justify-content: center;
    --align-content: space-between;
    align-items: stretch;
    gap: 50px;
}
.video {
	width: 50vw;
    height: calc(50vw / (16/9));
    margin: 0 auto; 
}
.video iframe {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.button-purchase {
    background-color: var(--color-yellow);
    box-shadow: 0px 0px 20px var(--color-dark-purple);
    position: relative;
    padding: 25px;
    width: 100%;
    margin: 0 auto;
    border-radius: 9999px;
    box-sizing: border-box;
    content: '';
}

.box-text-container {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    position: absolute;
    margin-left: 40px;
}
.test-flex {
    display: flex;
    align-items: center;
    --justify-content: center;
    justify-content: flex-start;
    width: 70%;
    margin: 0 auto;
}
@keyframes scalebutton {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.1);
    }
}
.test-flex:hover {
    animation: scalebutton 0.2s linear forwards;
}

.box {
    width: 55px;
}
.button-box-text {
    color: white;
    color: black;
    color: var(--color-dark-purple);
    font-family: 'Rubik-SemiBold';
    letter-spacing: 1px;
    word-spacing: 2px;
    margin: 0px;
}
.button-container {
    display: flex;
    justify-content: center;
}
.button-yellow {
    display: inline-block;
    border: none;
    border-radius: 100px;
    color: white;
    color: var(--color-dark-purple);
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
    font-size: 1em;
    --width: 100%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--color-yellow);
    font-family: 'Rubik-SemiBold';
    letter-spacing: 1px;
    word-spacing: 2px;
    text-align: center;
    box-shadow: 0px 5px 5px rgb(0, 0, 0, 0.25);
}


.button-secondary {
    border: none;
    color: var(--color-green);
    background-color: rgb(255, 255, 255);
    border-radius: 9999px;
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 1em;
    font-family: 'Rubik-SemiBold';
    letter-spacing: 1px;
    word-spacing: 2px;
}

.button:hover {
    cursor: pointer;
    background-color: #dda41d;

}

.sized-container {
    width: 90%;
    margin: 0 auto;
}

.game-section-purple {
    background-color: var(--color-purple);
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    color: white;
    overflow: visible;
    z-index: 1;
}
 .game-section-purple:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: inherit;
    z-index: -1;
    top: 0;
    transform-origin: right top;
    transform: skewY(-3deg);
  }
  .game-section-purple:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: inherit;
    z-index: -1;
    top: 0;
    transform-origin: left top;
    transform: skewY(-3deg);
  }
  

.game-section-light {
    background-color: var(--color-light-purple);
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;
    color: #414141;
}

.instructions-group {
    padding-bottom: 40px;
}

.game-subtitle {
    --margin: 0 auto;
    margin: 0px;
    margin-bottom: 20px;
    width: 100%;
    font-size: 1.5em;
    font-family: 'Rubik-SemiBold';
    text-align: center;
}
.instructions-subtitle {
    margin: 0px;
    margin-bottom: 20px;
    width: 100%;
    font-size: 1.5em;
    font-family: 'Rubik-SemiBold';
}
.purchase-subtitle {
    margin: 0px;
    margin-bottom: 10px;
    width: 100%;
    font-size: 1.5em;
    font-family: 'Rubik-SemiBold';
}

.game-text {
    margin: 0 auto;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    line-height: 150%;
}
.instructions-text {
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 150%;
}
.purchase-text {
    margin: 0 auto;
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 150%;
}

.game-text-special {
    margin: 0 auto;
    margin-top: 0px;
    margin-bottom: 40px;
    text-align: center;
    line-height: 150%;
    width: 70%;
    color: var(--color-purple);
    font-family: 'Rubik-SemiBold';

}
.game-image-sizeA-wrapper {
    position: relative;
    height: 0;
    /* Formula is: (height / width * 100%) */
    padding-top: calc(750 / 1000 * 100%);
}
.game-image-sizeB-wrapper {
    position: relative;
    height: 0;
    /* Formula is: (height / width * 100%) */
    padding-top: calc(480 / 1000 * 100%);
}
.game-image-sizeC-wrapper {
    position: relative;
    height: 0;
    /* Formula is: (height / width * 100%) */
    padding-top: calc(700 / 500 * 100%);
}
.game-image-sizeD-wrapper {
    position: relative;
    height: 0;
    /* Formula is: (height / width * 100%) */
    padding-top: calc(541 / 1000 * 100%);
}
.game-image-sizeE-wrapper {
    position: relative;
    height: 0;
    /* Formula is: (height / width * 100%) */
    padding-top: calc(434 / 1000 * 100%);
}
.game-image-sizeF-wrapper {
    position: relative;
    height: 0;
    /* Formula is: (height / width * 100%) */
    padding-top: calc(506 / 1000 * 100%);
}
.game-image-sizeG-wrapper {
    position: relative;
    height: 0;
    /* Formula is: (height / width * 100%) */
    padding-top: calc(563 / 1000 * 100%);
}
.grid-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
}
#image1 {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0 !important;
    width: 100%;
    max-width: 100%;
    height: auto; 
}
@keyframes bounceIn {
    0% {
        opacity: 0;
        --transform: translateX(30px);
        scale: 0.9;
    }
    50% {
        opacity: 0.75;
        --transform: translateX(30px);
        scale: 1.1;
    }
    100% {
        opacity: 1;
        --transform: translateX(0px);
        scale: 1;
    }
}
.game-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: auto; 
}
.game-image-container {
    width: 100%;
    text-align: center;
}
.game-image-radio {
    width: 80%;
}
.secret-image {
    opacity: 0;
    width: 0px;
    height: 0px;
    position: absolute;
    top:0px;
    left:0px;
}

.beer-can-tl1 {
    position: absolute;
    width: 50px;
    opacity: 0.3;

    top: 0px;
    left: 0px;
    transform: translateX(-15px) translateY(50px) rotate(15deg);
}
.beer-can-tr1 {
    position: absolute;
    width: 50px;
    opacity: 0.3;

    top: 0px;
    right: 0px;
    transform: translateX(15px) translateY(20px) rotate(-15deg);
}
.beer-can-tr2 {
    position: absolute;
    width: 50px;
    opacity: 0.3;

    top: 0px;
    right: 0px;
    transform: translateX(15px) translateY(200px) rotate(15deg);

    z-index: 0;
}
.beer-can-bl1 {
    position: absolute;
    width: 50px;
    opacity: 0.3;

    bottom: 0px;
    left: 0px;
    transform: translateX(-15px) translateY(-40px) rotate(-15deg);
}
.beer-can-br1 {
    position: absolute;
    width: 50px;
    opacity: 0.3;

    bottom: 0px;
    right: 0px;
    transform: translateX(15px) translateY(-150px) rotate(15deg);
}

.kickstarter-link-container {
    display: flex;
    align-items: center;
    color: #00AA4A;
    gap: 10px;
    font-family: 'Rubik-SemiBold';
    justify-content: center;
}
.kickstarter-link-container:hover {
    cursor: pointer;
    animation: scalebutton 0.2s linear forwards;

}

.arrow-icon {
    width: 15px;
}

.triangle-bottom {
    min-width: 100%;
    position: absolute;
    bottom: 0;
    transform: translateY(1px);
    display:none;
}
.triangle-top {
    min-width: 100%;
    position: absolute;
    top: 0;
    transform: translateY(-1px) scaleX(-1) scaleY(-1);
    display:none;

}

.cart-container {
    display: flex;
    gap: 30px;
}

.image-container {
    width: 20%;
    height: auto;
    flex-shrink: 0;
}

.image-container img {
    width: 100%;
}

.purchase-table {
    text-align: left;
    width: 100%;
    margin-top: 30px;
    border-collapse: collapse;

}
.purchase-table th, .purchase-table td {
    padding: 15px;
  }
  
.purchase-table tr {
    border-top: 1px solid rgb(46, 33, 59, 0.25);
    border-bottom: 1px solid rgb(46, 33, 59, 0.25);
    font-family: 'Rubik-Light';

}

.purchase-table th {
    padding-right: 100px;
    font-family: 'Rubik-SemiBold';
    font-weight: normal;

}

.purchase-table td {
    width: 100%;
    font-family: 'Rubik-Light';

}

.checkout-button {
    border: 2px solid var(--color-purple);
    border-radius: 5px;
    text-align: center;
    padding: 15px;
    color: var(--color-purple);
    margin-top: 30px;
    font-family: 'Rubik-Light';
    font-weight: bold;
    box-sizing: border-box;
}
.checkout-button:hover {
    cursor: pointer;
}
.paypal-container {
    margin-top: 20px;
    background-color: var(--color-light-purple);
    position: relative;
}
.paypal-loader {
    position: absolute;
    top: 0;
    width: 100%;
    height: 50px;
    --background-color: red;
    overflow: hidden;
    z-index: 1;

}
.paypal-buttons {
    background-color: var(--color-light-purple);
    z-index: 20;
}
.toc-links {
    --margin-left: 5px;
}
.toc-title {
    margin: 0px;
    margin-bottom: 5px;
    padding-bottom: 2px;
    display: inline-block;
    font-size: 0.7em;
    font-family: 'Rubik-SemiBold';

}
.toc-item {
    margin: 0px;
    margin-bottom: 5px;
    padding-bottom: 2px;
    border-bottom: 2px solid var(--color-green);
    display: inline-block;
    font-size: 0.7em;
    font-weight: bold;
    color: rgb(64, 64, 64, 0.8);
}
.toc-item:hover {
    color: #414141;
    color: var(--color-green);
    cursor: pointer;
}
.toc-divider {
    margin: 0px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    display: inline-block;
    font-size: 0.7em;
}
@keyframes colorFade {
    0% {
        color: var(--color-green);
    }
    100% {
        color: var(--color-text);
    }
}
































.ContentGroupA {
    margin-top: 60px;
    position: relative;
}

.AngledDiv {
    min-width: 200%;
    height: 100%;
    position: absolute;
    margin:0 auto;
    overflow: hidden;
    background-color: var(--color-purple);
    transform: rotate(-5deg) translateX(-25%) translateY(-12.5%);
    overflow: hidden;
    --z-index: 100;
    top:0;
}
.AngledDivContent {
    --z-index: 1000;
    position: relative;
    padding-top: 60px;
    padding-bottom: 60px;
}

.ContentTitle {
    margin: 0;
    text-align: center;
    color: white;
    padding: 20px;
}

.ContentSectionTitle {
    margin: 0;
    color: #414141;
    font-size: 1.5em;
    font-family: 'Rubik-SemiBold';
    text-align: center;
}
.ContentSectionTitleWhite {
    margin: 0;
    color: white;
    font-size: 1.5em;
    font-family: 'Rubik-SemiBold';
    text-align: center;
}

.ContentSectionText {
    margin: 0 auto;
    margin-top: 10px;
    color: #414141;
    text-align: center;
    line-height: 150%;
    width: 80%;
    font-weight: 600;
}
.ContentSectionTextWhite {
    margin: 0 auto;
    margin-top: 10px;
    color: white;
    text-align: center;
    line-height: 150%;
    width: 80%;
    font-weight: 600;
}

.ContentSectionTextBold {
    margin: 0;
    margin-top: 20px;
    color: white;
    font-family: 'Rubik-SemiBold';
}

.radio-container {
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    gap: 5px;
    justify-content: center;
    box-sizing: border-box;
}

.radio {
    flex-grow: 1;
    --flex-shrink: 1;
    flex-basis: auto;
    flex: 1;
    width: 100%;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    --overflow: visible;
    width:100%;
}
.radio:hover {
    cursor: pointer;
    background-color: rgb(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0px 0px 25px rgb(0, 0, 0, 0.25);
}
.radio-selected {
    flex-grow: 1;
    --flex-shrink: 1;
    flex-basis: auto;
    flex: 1;
    width: 100%;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0px 0px 25px rgb(0, 0, 0, 0.25);
    --transform: scale(1.1);
    --overflow: visible;
    width: 100%;
}
.radio-selected:hover {
    cursor: pointer
}
.radio-text {
    margin: 0;
    margin-bottom: 10px;
    font-family: 'Rubik-SemiBold';
    font-size: 0.8em;
}
.radio-message-container {
    min-height: 100px;
    width: 100%;
    align-items: center;
}
.card-stack {
    width: 100%;
}

.ContentRadioSelected {
    flex: 1;
    text-align: center;
    padding: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    border: 1px solid rgb(228, 228, 228);
    background-color: rgb(255, 255, 255, 0.75);
    border-radius: 10px;
}



.ContentCardGrid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 10px 10px;
    margin-top: 20px;
}

.ContentCardGridItem {
    
}

.ContentCard {
    width: 100%;
    border-radius: 5px;
}



.ContentImageLarge {
    width: 100%;
    margin-top: 20px;
}

.ContentImageAd {
    width: 100%;
    margin-top: 20px;
    border-radius: 7px;
    border: 7px solid white;
    box-sizing: border-box;
}



.ContentSectionPurchaseUI {
    margin-top: 40px;
    background-color: white;
    padding: 10px;
    border-radius: 7px;
    box-shadow: 0 0 15px black;
    text-align: center;
}

.ContentSectionPurchaseUI:hover {
    box-shadow: 0 0 15px white;
}

.ContentPurchaseButton {
    color: rgb(51, 51, 51);
    text-align: center;
    padding: 20px;
    font-family: 'Rubik-SemiBold';
    border-radius: 7px;
    --margin-top: 20px;
    --margin-bottom: 20px;
    background-color: #F2B529;
    --background-image: linear-gradient(to bottom, #F2B529 , #cc9b2a, #F2B529);
}

.ContentPurchaseButton:hover {
    cursor: pointer;
    background-image: none;
    background-color: #F2B529;
    
}

.ContentSectionPurchaseText {
    margin: 0;
    --margin-top: 20px;
    color: black;
    font-family: 'Rubik-SemiBold';
    text-align: center;
}

@media only screen and (orientation: portrait) {

    .header {
        position: sticky;
        top: 0px;
        z-index: 999999999;
    }
}



@media only screen and (orientation: landscape) {
    .sized-container {
        width: 50%;
    }

    .scene {
        overflow: hidden;
        position: relative;
    }

    .scene-background {
        width: 100%;
        height: auto;
        position: absolute;
        opacity: 0.75;
        bottom: 0;
    }

    .video {
        width: 25vw;
        height: calc(25vw / (16/9));
    }
    
    .scene-ui-container {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 100vw;
        min-height: 500px;
        justify-content: center;
        --align-content: space-between;
        align-items: stretch;
        gap: 50px;
        --z-index: 1;
    }

    .test-flex {

        width: 40%;
    }

    .beer-can-tl1 {
        width: 150px;
        transform: translateX(-15px) translateY(50px) rotate(15deg);
    }
    
    .beer-can-tr1 {
        width: 150px;
        transform: translateX(15px) translateY(20px) rotate(-15deg);
    }
    .beer-can-tr2 {
        width: 150px;
        transform: translateX(15px) translateY(300px) rotate(15deg);
    }
    .beer-can-bl1 {
        width: 150px;
        transform: translateX(-15px) translateY(-40px) rotate(-15deg);
    }
    .beer-can-br1 {
        width: 150px;
        transform: translateX(15px) translateY(-150px) rotate(15deg);
    }

    .game-section-light {

        padding-top: 150px;
        padding-bottom: 150px;
    }
}

@media only screen and (orientation: landscape) and (max-width: 1300px) {
    .beer-can-tl1 {
        width: 80px;
        transform: translateX(-15px) translateY(50px) rotate(15deg);
    }
    
    .beer-can-tr1 {
        width: 80px;
        transform: translateX(15px) translateY(20px) rotate(-15deg);
    }
    .beer-can-tr2 {
        width: 80px;
        transform: translateX(15px) translateY(300px) rotate(15deg);
    }
    .beer-can-bl1 {
        width: 80px;
        transform: translateX(-15px) translateY(-40px) rotate(-15deg);
    }
    .beer-can-br1 {
        width: 80px;
        transform: translateX(15px) translateY(-150px) rotate(15deg);
    }

    
}

